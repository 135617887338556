<template>
  <div>
      <h1>Bind</h1>
      <v-btn @click="getBotList()">SHOW List</v-btn>
      <hr>

        <v-btn @click="chooseBot(bot.id)" v-for="bot in bots" :key="bot.id">
          {{ bot.display_name }}
        </v-btn>
 
      
  </div>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL;

export default {
  name: "Entrance",

  components: {},
  data() {
    return {
      bots: []
    }
  },
  mounted () {
      // let params = new URL(location.href).searchParams;
      this.getBotList();
      // TODO: show bot list 
  },
  methods: {
    async getBotList() {
        try {
            let resp = await axios.get(host_url + "/module/bots/");
            console.log(resp.data);
            this.bots = Object.assign({}, this.bots, resp.data)
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async chooseBot(botId) {
        try {
            let resp = await axios.post(host_url + `/module/bots/${botId}/choose/`);
            console.log(resp.data);
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    }
  },
};
</script>
